<template>
	<div class="about-area about-style-4 rn-section-gap">
		<div class="container">
			<div class="row row--40 align-items-center">
				<div class="col-lg-6">
					<Video :video-data="videoData" column="col-12" />
				</div>
				<div class="col-lg-6 mt_md--40 mt_sm--40">
					<div class="content">
						<div class="inner">
							<h3 class="title">
								蛙多宝是一家从事
								<br />是专注美蛙鱼头、特色火锅连锁开店
								<br />
								<strong>的火锅餐饮连锁企业.</strong>
							</h3>
							<ul class="feature-list">
								<li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">只卖鲜活蛙，新鲜的食材，制作诱人的美味</h4>
									</div>
								</li>
								<li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">直接服务到消费者的品牌</h4>
									</div>
								</li>
								<li>
									<Icon name="check" size="20" icon-class="icon" />
									<div class="title-wrapper">
										<h4 class="title">专为吃蛙爱好者服务的品牌</h4>
									</div>
								</li>
							</ul>
							<div class="about-btn mt--30">
								<router-link class="btn-default" to="/contact">联系我们</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Icon from '../../icon/Icon'
	import Video from "../video/Video";

	export default {
		name: 'AboutOne',
		components: { Video, Icon },
		props: {
			image: {}
		},
		data() {
			return {
				videoData: [
					{
						thumb: this.image,
						src: require(`@/assets/images/about/hg.png`),
						src: require(`@/assets/video/waduobao.mp4`),
					}
				]
			}
		}
	}
</script>
<style lang="scss" scoped>
.single-demo {
    background: #0C6B71;
    padding: 0;
}

.about-style-4 .feature-list li .icon {
    background-color: var(--color-vice) !important;
}

</style>>