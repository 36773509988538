<template>
  <router-view/>
</template>

<script>
export default {
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      // document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      // document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      // document.addEventListener("keydown", function (e) {
      //   if (e.key === "F12") {
      //     e.preventDefault(); // 如果按下键F12,阻止事件
      //   }
      // });
    })
  }
}
</script>

<style lang="scss">
/*body::-webkit-scrollbar {
  width: 8px;
  !*background-color: #F5F5F5;*!
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}*/
/* 滚动条样式 S */
$--scrollbar-track-color: rgba(181,101,42, 0.5);
// $--scrollbar-track-color: rgba(0, 0, 0, 0.5);
$--scrollbar-thumb-color: transparent;

// for Mozilla
@-moz-document url-prefix() {
  * {
    scrollbar-color: $--scrollbar-track-color $--scrollbar-thumb-color !important;
    scrollbar-width: thin !important;
  }
}

// for IE
.ie {
  &, * {
    scrollbar-arrow-color: $--scrollbar-track-color; // 三角箭头的颜色
    scrollbar-face-color: $--scrollbar-track-color; // 滚动条滑块按钮的颜色
    scrollbar-track-color: $--scrollbar-thumb-color; // 滚动条轨道颜色
    scrollbar-shadow-color: transparent; // 滚动条阴影
     //scrollbar-highlight-color: #0099dd; // 滚动条整体颜色
     //scrollbar-3dlight-color:#0099dd; // 滚动条3d亮色阴影边框的外观颜色
     //scrollbar-darkshadow-color: #0099dd; // 滚动条3d暗色阴影边框的外观颜色
     //scrollbar-base-color: #0099dd; // 滚动条基准颜色
  }
}

// for Webkit
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $--scrollbar-track-color;

  &:hover {
    background-color: lighten($--scrollbar-track-color, 20%);
  }
}

::-webkit-scrollbar-track-piece {
  border-radius: 4px;
  background-color: $--scrollbar-thumb-color;
}

::-webkit-scrollbar-corner {
  background-color: $--scrollbar-thumb-color;
}
/* 滚动条样式 E */
// .vue-directive-image-previewer{
//   z-index: 99;
// }
</style>
