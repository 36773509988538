<template>
	<!-- Start Copy Right Area  -->
	<div class="copyright-area copyright-style-one">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6 col-md-8 col-sm-12 col-12">
					<div class="copyright-left">
						<ul class="ft-menu link-hover">
							<!-- <li>
                                <router-link to="#">隐私政策</router-link>
							</li>-->
							<li>
								<router-link to="#">备案信息</router-link>
							</li>
							<!-- <li>
								<router-link to="/contact">版权信息</router-link>
							</li>-->
						</ul>
					</div>
				</div>
				<div class="col-lg-6 col-md-4 col-sm-12 col-12">
					<div class="copyright-right text-center text-lg-end">
						<p class="copyright-text">
							Copyright © {{ new Date().getFullYear() }} 蛙多宝 &nbsp;&nbsp;&nbsp;&nbsp; 技术支持：
							<span class="highinspire" @click="goHighinspire">云南高兴技术</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- End Copy Right Area  -->
</template>

<script>
	export default {
		name: 'Copyright',
		methods: {
			goHighinspire() {
				window.open('https://highinspire.cn/', "_blank");
			}



		}
	}
</script>
<style lang="scss" scoped>
	.highinspire {
		display: inline-block;
		cursor: pointer;
		&:hover {
			color: var(--color-vice);
		}
	}
</style>