<template>
    <div class="about">
        <div ref="echartMap" id="echartMap" style="height:800px;"></div>
    </div>
</template>
 
<script>
import * as echarts from 'echarts'
import gzMap from '@/database/yunnan.json'
export default {
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.init()
        })
    },
    methods: {
        init() {

            let that = this
            //初始化echarts实例
            var myChart = echarts.init(this.$refs.echartMap)
            //注入这个表
            echarts.registerMap('yunnan', gzMap);
            //指定图表配置项及数据
            var option = {

                series: [{
                    type: 'map',
                    map: 'yunnan',
                    zoom: 1.3,//放大地图
                    label: {
                        show: true
                    },

                }],

            }
            //使用刚指定的配置项及数据
            myChart.setOption(option);
            myChart.on('click', function (params) {
                console.log(params);
                that.$emit('clickMap', params)
                //逻辑控制

            });
        }
    },

}
</script>