<template>
    <Layout header-class="header-transparent" :show-cta="false">
        <div class="slider-area slider-style-1 height-850 background-box bg_image" data-black-overlay="2"
            :style="{ 'background-image': `url(${require(`@/assets/images/index/excellentStore/8.png`)})` }">
            <div class="container bg_image_text_box">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <!-- <h3 class="title display-one" data-aos="fade-up" data-aos-delay="150">
								“只卖鲜活蛙”
								<br />“直接服务到消费者的品牌”
								<br />“专为吃蛙爱好者服务的品牌”
							</h3>-->
                            <h3 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                客户至上，团队合作
                                <br />感恩付出，迎接变化
                                <br />积极向上，结果为王
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- End -->

        <Separator />

        <!-- Start 品牌故事 -->
        <div class="rn-demo-area rn-section-gap" id="demo">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30 bdr-img-1">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="品牌介绍" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="container">
                        <div class="row row--30 ">
                            <div class="col-lg-5">
                                <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                                    <img class="w-100" :src="require(`@/assets/images/brand/sales.png`)"
                                        alt="About Images" />
                                </div>
                            </div>
                            <div class="col-lg-7 mt_md--40 mt_sm--40">
                                <div class="content">
                                    <div class="section-title">
                                        <h4 data-aos="fade-up" data-aos-delay="310">
                                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                            蛙多宝美蛙鱼头火锅，研发于四川成都，2018 年诞生于四川成都，总公司位于四川成都，2022 年成立云南分公司。在
                                            2018年始的四年里，“蛙多宝”在云南昆明市成功运营6 家直营门店，2022 年 10 月至 2023 年 8月快速发展连锁店 26 家，公司现有员工
                                            300余人。
                                            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            自“蛙多宝”品牌成立以来，始终坚持选用优质食材，匠心制作，打造安全健康美食，公司打通所有相关供应链，只为合作人省心省力而行。“蛙多宝”以顾客的每一件小事都当成我们的大事作为服务宗旨，从技术技能培训到门店保姆式帮扶，为倾力打造一家“有人情味”的“蛙多宝人”餐厅而砥砺前行。
                                            <br />&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                            公司先后荣获“火锅行业创新领导先锋品牌”“全国特色火锅名店”、“中国诚信无投诉企业”、“中国火锅行业最具影响力品牌”等荣誉，离不开“蛙多宝人”一生只为一锅蛙而奋斗的使命。
                                        </h4>
                                        <div class="thumbnail col-lg-8 ptb--20" data-aos="fade-up" data-aos-delay="300">
                                            <img class="w-100" :src="require(`@/assets/images/brand/cn.png`)"
                                                alt="About Images" />
                                        </div>
                                        <p data-aos="fade-up" data-aos-delay="340"></p>
                                        <div class="read-more-btn mt--40" style="border-style:none !important;"
                                            data-aos="fade-up" data-aos-delay="370">
                                            <router-link class="btn-default" to="/contact">
                                                <span>联系我们</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 品牌故事 -->
        <Separator />
        <!-- Start 品牌介绍 -->
        <div class="content rn-section-gapTop padding-t-0" style="padding-top: 0px !important;" data-aos="fade-up">
            <SectionTitle text-align="center" subtitle title="打造一家有人情味的餐厅" description data-aos="fade-up" />
        </div>
        <div class="padding-t-0 service-area rn-section-gapTop ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 flex-center">
                        <div class="content brand-title" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle title="一、蛙多宝家人规矩:" description
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mb--10" data-aos="fade-up">
                            有情有义有规矩
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-area rn-section-gapTop padding-t-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 flex-center">
                        <div class="content brand-title" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle title="二、蛙多宝管理核心:" description
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mb--10" data-aos="fade-up">
                            活泼严肃
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-area rn-section-gapTop padding-t-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 flex-center">
                        <div class="content brand-title" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle title="三、蛙多宝工作方向:" description
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mb--10" data-aos="fade-up">
                            绝对服从坚决执行
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-area rn-section-gapTop padding-t-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 flex-center">
                        <div class="content brand-title" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle title="四、蛙多宝服务理念:" description
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mb--10" data-aos="fade-up">
                            亲戚试服务
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-area rn-section-gapTop padding-t-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 flex-center">
                        <div class="content brand-title" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle title="五、蛙多宝经营理念:" description
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mb--10" data-aos="fade-up">
                            好吃不贵还有人情味
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="service-area rn-section-gapTop padding-t-0" style="padding-bottom: 50px !important;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 flex-center">
                        <div class="content brand-title" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle title="六、蛙多宝经营宗旨:" description
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mb--10" data-aos="fade-up">
                            持久经营薄利多销
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 品牌介绍 -->
        <Separator />
        <!-- Start 负责人 -->
        <!-- <div class="rn-section-gap">
            <div class="plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Senior principal Andrea Bonomi" title="负责人"
                            description="下面是我们公司的负责人" data-aos="fade-up" />
                    </div>
                </div>
                <div class="slider-area slider-style-5 bg-overlay-solid height-850 bg_image image-background-black"
                    data-black-overlay="3"
                    :style="{ 'background-image': `url(${require(`@/assets/images/person-in-charge/man.png`)})` }">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner text-left">
                                    <h1 class data-aos="fade-up" data-aos-delay="100">李先生</h1>
                                    <h2 class="title display-one" data-aos="fade-up" data-aos-delay="150">创始人.</h2>
                                    <h4 class="description" data-aos="fade-up" data-aos-delay="150">
                                        直接服务到消费者的品牌
                                        <br />专为吃蛙爱好者服务的品牌
                                        <br />xxxxxxxxxxxxxxxxxxxx
                                        <br />xxxxxxxxxxxxxxxxxxxx
                                        <br />xxxxxxxxxxxxxxxxxxxx
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End 负责人 -->
        <Separator />
        <!-- Start team Area -->
        <!-- <div class="rwt-team-area rn-section-gap bg-vice" id="team">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="团队" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row bdr-img-1">

                    <div class="col-lg-6 col-xl-4 col-md-6 col-12 mt--30" v-for="(teamMember, index) in teamData"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="5" />
                    </div>
                </div>
            </div>
        </div>

        <Separator /> -->
        <!-- Start 资质 -->
        <div class="rn-demo-area rn-section-gap" id="success-case">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="All Successful Case" title="品牌资质"
                            description="下面是我们公司的品牌资质 <br /> 直观的的靠谱" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide width-400" v-for="(slider, index) in sliderData" :key="index">
                                <div class="bg_image mr--20 height-280">
                                    <img class="VueSlickCarouselimg"
                                        :src="require(`@/assets/images/brand/certification/${slider.image}.jpg`)" alt />
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div> -->
                    <!-- Start 二维码矩阵 -->
                    <div class="brandList">
                        <div v-for="(slider, index) in sliderData" :key="index">
                            <el-popover placement="top" trigger="hover">
                                <!-- <img class="el-popover-img"  :src="require(`@/assets/images/brand/certification/${slider.image}.jpg`)" alt /> -->
                                <div>{{slider.description}}</div>
                                <div slot="reference">
                                    <a href="javascript:void(0);">
                                        <!-- 抖音号-->
                                        <img class="el-popover-img bg_image mr--20 mb--20 height-280"
                                            :src="require(`@/assets/images/brand/certification/${slider.image}.jpg`)"
                                            alt="Sun images" />
                                    </a>
                                </div>
                            </el-popover>
                        </div>
                    </div>

                    <!-- <div class=" bg_image mr--20 height-250"
					:style="{ 'background-image': `url(${require(`@/assets/images/brand/certification/${slider.image}.jpg`)})` }">-->
                    <!-- <VueSlickCarousel v-bind="sliderSetting" :slidesToShow="1" class="d-block d-lg-none">
						<div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
							<div class=" bg_image mr--20 height-280">
								<img class="VueSlickCarouselimg"
									:src="require(`@/assets/images/brand/certification/${slider.image}.jpg`)" alt="">
							</div>
							<div class="inner text-center">
								<p class="description" v-html="slider.description" />
							</div>
						</div>
					</VueSlickCarousel>

					<VueSlickCarousel v-bind="sliderSetting" :slidesToShow="4" class="d-none d-lg-block">
						<div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
							<div class=" bg_image mr--20 height-280">
								<img class="VueSlickCarouselimg"
									:src="require(`@/assets/images/brand/certification/${slider.image}.jpg`)" alt="">
							</div>
							<div class="inner text-center">
								<p class="description" v-html="slider.description" />
							</div>
						</div>
					</VueSlickCarousel>-->
                </div>
            </div>
        </div>
        <!-- End 成功案列 -->
    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import BlogPost from "@/components/blog/BlogPost";
import Team from '@/components/elements/team/Team'
import Testimonial from "@/components/elements/testimonial/Testimonial";
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPostMixin from '@/mixins/BlogPostMixin'
import Swiper from "swiper"
export default {
    name: 'Index',
    components: { VueSlickCarousel, Team, Icon, Layout, Separator, SectionTitle, BlogPost, Testimonial },
    mixins: [BlogPostMixin],
    data() {
        return {
            videoData:
            {
                thumb: require(`@/assets/images/brand/cover.png`),
                src: require(`@/assets/video/waduobao.mp4`),
            },
            sliderSetting: {
                // multiple: true,
                accessibility: false,
                arrows: true,
                // infinite: true,
                dots: false,
                arrows: true,
                autoplay: true,
                speed: 3000,
                autoplaySpeed: 0,
                cssEase: 'linear',
                initialSlide: 0,
                // variableWidth: true,
                // slidesToShow: 4,
                slidesToScroll: 1,
                swipeToSlide: true

            },
            //资质
            sliderData: [

                {
                    image: 'certification1',
                    description: '火锅行业创新领导先锋品牌（牌匾）',
                },
                {
                    image: 'certification1-1',
                    description: '火锅行业创新领导先锋品牌（证书）',
                },
                {
                    image: 'certification2',
                    description: '全国特色火锅名店（牌匾）',
                },
                {
                    image: 'certification2-1',
                    description: '全国特色火锅名店（证书）',
                },
                {
                    image: 'certification3',
                    description: '中国诚信无投诉企业（牌匾）',
                },
                {
                    image: 'certification3-1',
                    description: '中国诚信无投诉企业（证书）',
                },
                {
                    image: 'certification4',
                    description: '中国火锅行业连锁首选品牌（牌匾）',
                },
                {
                    image: 'certification4-1',
                    description: '中国火锅行业连锁首选品牌（证书）',
                },
                {
                    image: 'certification5',
                    description: '中国火锅行业优秀企业（牌匾）',
                },
                {
                    image: 'certification5-1',
                    description: '中国火锅行业优秀企业（证书）',
                },
                {
                    image: 'certification6',
                    description: '中国火锅行业最具影响力品牌（牌匾）',
                },
                {
                    image: 'certification6-1',
                    description: '中国火锅行业最具影响力品牌（证书）',
                }, {
                    image: 'certification0',
                    description: '商业特许经营（证书）',
                },
            ],

            teamData: [
                {
                    image: 'team-1',
                    name: '资质1',
                    designation: 'initiator',
                    location: '相互成就',
                    description: '态度决定一切'
                },
                {
                    image: 'team-2',
                    name: '资质2',
                    designation: 'senior web',
                    location: '资深前端',
                    description: '钻的更深，看的更透'
                },
                {
                    image: 'team-3',
                    name: '资质3',
                    designation: 'architect',
                    location: '大牛架构师',
                    description: '好的架构是一门艺术'
                },
                {
                    image: 'team-4',
                    name: '资质4',
                    designation: 'UI',
                    location: '灵动美工',
                    description: '感知美，发现美，创造美'
                },
            ],
            teamData: [
                {
                    image: 'employee',
                    name: '',
                    designation: '',
                    location: '',
                    description: ''
                },
                {
                    image: 'employee1',
                    name: '',
                    designation: '',
                    location: '',
                    description: ''
                }, {
                    image: 'employee2',
                    name: '',
                    designation: '',
                    location: '',
                    description: ''
                },
            ],

        }
    },
    mounted() {
        //在mounted调用，因为这里dom已经初始化完成，js逻辑可以作用于dom了
        this.bannerPoint()
    },
    methods: {
        bannerPoint() {
            //这里return 我是设置了eslint规则，new字段不能单独写，要进行赋值
            return new Swiper('.swiper-container', {
                loop: true,
                speed: 8000,
                // autoplay: {
                //     delay: 0, // 1秒切换一次
                //     disableOnInteraction: false,
                //     stopOnLastSlide: false,
                //     // waitForTransition: false,
                // },
                autoplay: false,
                freeMode: true,
                slidesPerView: 'auto',
                loopedSlides: 6,
                updateOnImagesReady: true,
                loopFillGroupWithBlank: true,
                spaceBetween: 0,
                // spaceBetween : '10%',按container的百分比

                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            })
        },
        goDetails(row) {
            // this.$router.push({
            // 	path: `/solution-details/${row.id}`,
            // })
            let routeUrl = this.$router.resolve({
                path: `/solution-details/${row.id}`,
            });
            window.open(routeUrl.href, '_blank');

        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
}

img {
    vertical-align: top;
}

.background-box {
    position: relative;
    z-index: 0;

    .videoContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -100;
    }

    .videoContainer:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        z-index: -1;
        top: 0;
        left: 0;
        background: rgba(25, 29, 34, 0.65);
        background-size: cover;
    }

    .fullscreenVideo {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .w-brand {
        width: 50% !important;
        max-width: 50%;
    }
}

.bg_image_text_box {
    // background: rgba(0, 0, 0, 0.5);
    background: radial-gradient(circle, rgba(0, 0, 0, 0.7), transparent);
}

.brand-title {
    .section-title {
        padding: 0 15px 0 10px;
        text-align: left;
        background: linear-gradient(90deg, rgb(244, 207, 43) 0%, rgb(244, 202, 42) 6%, rgb(243, 193, 40) 12%, rgb(243, 186, 39) 20%, rgb(242, 174, 36) 41%, rgb(242, 169, 35) 53%, rgb(241, 154, 32) 64%, rgb(241, 146, 30) 77%, rgb(240, 137, 28) 100%) !important;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -40px;
            /* 三角形宽度 */
            width: 0;
            height: 0;
            // border-top: 40px solid transparent;
            border-bottom: 54px solid transparent;
            border-left: 40px solid rgb(240, 137, 28);
            /* 三角形颜色 */
        }
    }

}

.padding-t-0 {
    padding-top: 30px !important;
}

.brandList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
    // flex-wrap: wrap;
}

.icon-img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.el-popover-img {
    width: 400px;
    // height: 100px;
    display: block;
    margin: auto;
}
.el-popover-img-show {
    // width: 400px;
    // height: 100px;
    display: block;
    margin: auto;
}
</style>
