import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/Index.vue'
import Brand from '@/views/brand'
import Store from '@/views/store'
import Help from '@/views/help'
import Cooperation from '@/views/cooperation'
import ContactPage from '@/views/contact/ContactPage'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: '蛙多宝',
        },
    },
    {
        path: '/brand',
        name: 'Brand',
        component: Brand,
        meta: {
            title: '公司品牌',
        },
    },
    {
        path: '/store',
        name: 'Store',
        component: Store,
        meta: {
            title: '门店',
        },
    },
    {
        path: '/cooperation',
        name: 'Cooperation',
        component: Cooperation,
        meta: {
            title: '合作方式',
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            title: '总部扶持',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: '联系我们',
        },
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
