import AppFunctions from '../helpers/AppFunctions'
export default {
    data() {
        return {
            posts: [],
            categories: '',
            tags: '',
            searchValue: '',
            searchList: [],
            AppFunctions
        }
    },
    methods: {
        blogCategories() {
            let cats = this.posts.map(item => {
                return item.categories;
            }),
                singleCatArray = AppFunctions.flatDeep(cats),
                categories = [];

            singleCatArray.forEach(cat => {
                const obj = {
                    title: cat.trim(),
                    slug: AppFunctions.slugify(cat),
                    count: 1
                }
                const objIndex = AppFunctions.containsObject(obj, categories);
                if (objIndex !== -1) {
                    const prevCount = categories[objIndex].count;
                    categories[objIndex] = {
                        title: cat.trim(),
                        slug: AppFunctions.slugify(cat),
                        count: prevCount + 1
                    }
                } else {
                    categories.push(obj);
                }
            });

            this.categories = categories;
        },
        blogTags() {
            let tags = this.posts.map(item => {
                return item.tags;
            }),
                singleTagArray = AppFunctions.flatDeep(tags),
                tagsData = [];

            singleTagArray.forEach(tag => {
                const obj = {
                    title: tag.trim(),
                    slug: AppFunctions.slugify(tag)
                },
                    objIndex = AppFunctions.containsObject(obj, tagsData);

                if (objIndex !== -1) {
                    tagsData[objIndex] = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    }
                } else {
                    tagsData.push(obj);
                }
            });

            this.tags = tagsData;
        },
        search() {
            let searchList = this.posts.filter(item => {
                return item.title.indexOf(this.searchValue) != -1
            })
            this.searchList = searchList
        },
        clearSearchValue() {
            this.searchList = JSON.parse(JSON.stringify(this.posts))
        }
    },
    created() {
        this.searchList = JSON.parse(JSON.stringify(this.posts))
        this.blogCategories();
        this.blogTags();
    }
}

