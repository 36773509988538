<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle text-align="center" subtitle="Contact Details" title="我们的联系方式" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-24 col-md-24 col-24">
                                    <div class="rn-address">
                                        <div class="rn-address-content">
                                            <div class="icon-wrapper">
                                                <div class="icon">
                                                    <Icon name="headphones" iconClass="color-white" :size="40" />
                                                </div>
                                                <div class="title">联系电话</div>
                                            </div>
                                            <div class="inner">
                                                <h3>
                                                    <a href="tel:4006899001">4006899001</a>
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="rn-address-content">
                                            <div class="icon-wrapper">
                                                <img class="icon-img"
                                                    :src="require(`@/assets/images/icons/douyinlight.svg`)"
                                                    alt="Sun images" style="width: 30px; height: 30px;" />
                                                <div class="title">抖音</div>
                                            </div>
                                            <div class="inner">
                                                <el-popover placement="top" trigger="hover">
                                                    <img class="el-popover-img"
                                                        :src="require('@/assets/images/about/douyin.png')" alt />
                                                    <div slot="reference">
                                                        <a href="javascript:void(0);">
                                                            <!-- 抖音号-->
                                                            <img class="icon-img light-icon"
                                                                :src="require(`@/assets/images/about/douyin.png`)"
                                                                alt="Sun images" />
                                                        </a>
                                                    </div>
                                                </el-popover>
                                            </div>
                                        </div>
                                        <div class="rn-address-content">
                                            <div class="icon-wrapper">
                                                <div class="icon">
                                                    <img class="icon-img"
                                                        :src="require(`@/assets/images/icons/wechatlight.svg`)"
                                                        alt="Sun images" style="width: 40px; height: 40px;" />
                                                    <!-- <Icon name="wechat" iconClass="color-white" :size="40" /> -->
                                                </div>
                                                <div class="title">微信</div>
                                            </div>
                                            <div class="inner">
                                                <el-popover placement="top" trigger="hover">
                                                    <img class="el-popover-img"
                                                        :src="require('@/assets/images/about/headquarters.png')" alt />
                                                    <div slot="reference">
                                                        <a href="javascript:void(0);">
                                                            <!-- 抖音号-->
                                                            <img class="icon-img light-icon"
                                                                :src="require(`@/assets/images/about/headquarters.png`)"
                                                                alt="Sun images" />
                                                        </a>
                                                    </div>
                                                </el-popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->

        <!-- Start 云南分部地址 -->
        <!-- <Separator />
		<div class="rn-demo-area rn-section-gap " id="user-story">
			<div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="" title="云南分部地址"
							description="下面是我们店的云南分部地址 <br /> 一起来感受专业态度带来的不同" data-aos="fade-up" />
					</div>
				</div>
				<div class="row ">
					<div class="col-lg-5 col-xl-6 order-1 order-lg-1">
						<YunNanMap @clickMap="clickMapFn" />
					</div>
					<div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40 height-850"
						style="flex-direction: column;">
						<h1>{{ tableTitle }}</h1>
						<el-table :data="tableData" style="width: 100%;" max-height="650">
							<el-table-column prop="name" label="名称">
							</el-table-column>
							<el-table-column prop="address" label="地址">
							</el-table-column>
							<el-table-column prop="phone" label="电话">
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>-->
        <!-- End 云南分部地址 -->
        <Separator />
        <!-- Start 二维码矩阵 -->
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="合作伙伴" data-aos="fade-up"
                            data-aos-delay="60" />
                    </div>
                </div>

                <div class="brandList">
                    <div v-for="(brand, index) in brandList" :key="index">
                        <el-popover placement="top" trigger="hover">
                            <img class="el-popover-img" :src="brand.image" alt />
                            <div slot="reference">
                                <a href="javascript:void(0);">
                                    <!-- 抖音号-->
                                    <img class="icon-img light-icon" :src="brand.image" alt="Sun images" />
                                </a>
                            </div>
                        </el-popover>
                    </div>

                    <!-- <Brand :brand-list="brandList" :brand-style="3" /> -->
                </div>
            </div>
        </div>

        <!-- End 二维码矩阵 -->
    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Icon from "@/components/icon/Icon";
import Separator from '@/components/elements/separator/Separator'
import YunNanMap from "@/components/map/YunNanMap.vue";
import Team from '@/components/elements/team/Team'
export default {
    name: 'ContactPage',
    components: { Icon, SectionTitle, Breadcrumb, Layout, Separator, YunNanMap, Team },
    data() {
        return {

            brandList: [
                {
                    href: "https://aliyun.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.nginx.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.189.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://aliyun.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.ynnu.edu.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "http://www.repairct.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.ynskw.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://colab.research.google.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.tapd.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.toptal.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.thoughtworks.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
            ],
            tableTitle: '',
            tableData: []
        }
    },
    methods: {
        clickMapFn(e) {
            this.tableTitle = e.name
            this.tableData = [
                { name: 'XX区哇夺宝美蛙鱼头火锅', address: '测试地址', phone: '1580444455555' },
                { name: 'XX区哇夺宝美蛙鱼头火锅', address: '测试地址', phone: '1580444455555' },
                { name: 'XX区哇夺宝美蛙鱼头火锅', address: '测试地址', phone: '1580444455555' },
                { name: 'XX区哇夺宝美蛙鱼头火锅', address: '测试地址', phone: '1580444455555' },
                { name: 'XX区哇夺宝美蛙鱼头火锅', address: '测试地址', phone: '1580444455555' },
            ]
        }
    },
}
</script>
<style lang="scss" scoped>
.single-demo {
    background: #0c6b71;
    padding: 0;
}

.icon-img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.el-popover-img {
    width: 400px;
    // height: 100px;
    display: block;
    margin: auto;
}

.brandList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.rn-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .rn-address-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
        margin: 0;
    }

    .icon-img {
        margin: 0;
    }

    .title {
        // 
        font-size: 28px;
        font-weight: 600;
        margin: 0;
        margin-left: 5px;
    }
}
</style>