<template>
    <div v-if="timelineStyle === 1" class="rn-timeline-wrapper timeline-style-one position-relative">
        <div class="timeline-line" />
        <div class="single-timeline mt--50" v-for="(timeline, index) in timelineData" :key="index">
            <div class="timeline-dot">
                <div class="time-line-circle" />
            </div>
            <div class="single-content">
                <div class="inner" :style="timeline.image?'':'padding:20px'">
                    <div class="row row--30 align-items-center">
                        <div class="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40" :class="timeline.image?'col-lg-6':'col-lg-12'">
                            <div class="content">
                                <span class="date-of-timeline" data-aos="fade-up" data-aos-delay="50">
                                    {{ timeline.date }}
                                </span>
                                <h2 class="title" data-aos="fade-up" data-aos-delay="80">
                                    {{ timeline.title }}
                                </h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="110">
                                    {{ timeline.description }}
                                </p>

                                <div class="row row--30">
                                    <div class="col-lg-6 col-md-6 col-12" v-for="(step, index) in timeline.workingStep"
                                        :key="index" data-aos="fade-up" data-aos-delay="140">
                                        <div class="working-list">
                                            <h5 class="working-title" data-aos="fade-up" data-aos-delay="60">
                                                {{ step.stepTitle }}
                                            </h5>
                                            <p data-aos="fade-up" data-aos-delay="60">
                                                {{ step.stepDescription }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="read-morebtn">
                                    <a class="btn-default btn-large round"
                                       href="#"
                                       data-aos="fade-up"
                                       data-aos-delay="170">
                                        <span>Get Started Now</span>
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <div class="order-1 order-lg-2 col-lg-6" v-if="timeline.image">
                            <div class="thumbnail" data-aos="fade-up">
                                <!-- <img   v-image-preview class="w-100" -->
                                <el-image :preview-src-list='[timeline.image]' class="w-100" :src="timeline.image"
                                    alt="Corporate Vue Template" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="timelineStyle === 2 || timelineStyle === 3 || timelineStyle === 4"
        class="timeline-style-two bg-color">
        <div class="row row--0">
            <div :class="`col-lg-3 col-md-3 rn-timeline-single ${checkTimelineStyle}`"
                v-for="(timeline, index) in timelineData" :key="index">
                <div class="rn-timeline">
                    <h6 class="title" data-aos="fade-up" data-aos-delay="60">
                        {{ timeline.title }}
                    </h6>
                    <div class="progress-line">
                        <div class="line-inner" />
                    </div>
                    <div class="progress-dot">
                        <div class="dot-level">
                            <div class="dot-inner" />
                        </div>
                    </div>
                    <p class="description" data-aos="fade-up" data-aos-delay="60">
                        {{ timeline.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Timeline',
    props: {
        timelineData: {},
        timelineStyle: {
            type: Number,
            default: 1
        }
    },
    computed: {
        checkTimelineStyle() {
            if (this.timelineStyle === 3) return 'no-gradient';
            else if (this.timelineStyle === 4) return 'dark-line';
            else return '';
        }
    }
}
</script>
<style lang="scss" scoped>

.bg-color,.joining-process  .single-content {
    // background: linear-gradient(180deg, var(--color-dark) 20%, rgba(19, 19, 19, 0.4)) !important;
    // -webkit-backdrop-filter: blur(4px) opacity(0.95);
    background: linear-gradient(90deg, rgba(0,128,122,1) 0%, rgba(2,125,121,1) 33%, rgba(4,121,120,1) 53%, rgba(6,116,118,1) 64%, rgba(8,113,117,1) 79%, rgba(11,107,115,1) 100%);
}
</style>>