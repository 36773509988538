<template>
    <Layout :footer-style="2">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-650 bg-overlay bg_image"
                        :style="{ 'background-image': `url(${require(`@/assets/images/index/banner/${slider.image}.png`)})` }">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title" />
                                        <p class="description" v-html="slider.description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->
        <Separator />
        <!-- Start 人气现场 -->
        <div class="rn-demo-area rn-section-gap" id="user-story">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="人气现场"
                            description="蛙多宝美蛙鱼头火锅，深受广大消费者喜爱。<br /> 火爆用餐现场" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-3 col-md-6 col-12 mb--20" v-for="(item, index) in sceneList" :key="index">
                        <div class="single-demo">
                            <div class="thumbnail">
                                <el-image class="image-dark banner_img" :preview-src-list="sceneList" :src="item"
                                    style="width: 100%;height: 270px;background-size: 100% 100%;"
                                    alt="Testimonial image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 人气现场 -->
        <!-- Start 门店展示 -->
        <div class="rn-demo-area rn-section-gap bg-vice" id="user-story">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="门店展示" description data-aos="fade-up" />
                        <h3 data-aos="fade-up" class="text-center" style="color:var(--color-pinterest)">
                            全国门店32家，正在签约选址15家
                        </h3>
                        <!-- <h4 class="text-center" data-aos="fade-up">优秀门店展示</h4> -->
                    </div>
                </div>
                <div class="row row--15">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide width-390" v-for="(slider, index) in excellentStoreList"
                                :key="index">
                                <div class="bg_image mr--20 height-280">
                                    <img class="VueSlickCarouselimg "
                                        :src="require(`@/assets/images/index/excellentStore/${slider.image}.png`)"
                                        alt />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <VueSlickCarousel v-bind="shopSliderSetting" :slidesToShow="1" class="d-block d-lg-none">
                        <div v-for="(slider, index) in excellentStoreList" :key="index" class="single-slide">
                            <div class="bg-overlay bg_image height-280 mr--20">
                                <img class=" VueSlickCarouselimg  "
                                    :src="require(`@/assets/images/index/excellentStore/${slider.image}.png`)" alt="">
                            </div>
                        </div>
                    </VueSlickCarousel>
                    <VueSlickCarousel v-bind="shopSliderSetting" :slidesToShow="4" class="d-none d-lg-block">
                        <div v-for="(slider, index) in excellentStoreList" :key="index" class="single-slide">
                            <div class="bg-overlay bg_image height-280 mr--20">
                                <img class=" VueSlickCarouselimg  "
                                    :src="require(`@/assets/images/index/excellentStore/${slider.image}.png`)" alt="">
                            </div>
                        </div>
					</VueSlickCarousel>-->
                </div>
            </div>
        </div>
        <!-- End 门店展示 -->
        <Separator />
        <!-- Start 产品介绍 -->
        <div class="rn-section-ga row pt--60">
            <div class="col-lg-12">
                <SectionTitle text-align="center" subtitle title="产品介绍" data-aos="fade-up" data-aos-delay="100" />
            </div>
        </div>
        <div class="slider-style-8 ptb--20" description data-aos="fade-up">
            <img class="w-100" :src="require(`@/assets/images/index/shop/title_b.png`)" alt="About Images" />
            <img class="w-100 ptb--20" :src="require(`@/assets/images/index/shop/title_t.png`)" alt="About Images" />
        </div>
        <div class="rn-section-gap" style="padding-top:20px !important;">
            <!--<div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="inner text-center">
                            <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">锅底口味
                            </h1>
                            <div class="bottom-box">
                                <div class="bottom-item" data-aos="fade-up" data-aos-delay="100">
                                    <img class="bottom-img" :src="require(`@/assets/images/index/shop/1.png`)"
                                        alt="Banner Images" />
                                    <div class="bottom-title">
                                        香辣红油味
                                    </div>
                                </div>
                                <div class="bottom-item" data-aos="fade-up" data-aos-delay="100">
                                    <img class="bottom-img" :src="require(`@/assets/images/index/shop/1.png`)"
                                        alt="Banner Images" />
                                    <div class="bottom-title">
                                        酸菜鲜辣味
                                    </div>

                                </div>
                                <div class="bottom-item" data-aos="fade-up" data-aos-delay="100">
                                    <img class="bottom-img" :src="require(`@/assets/images/index/shop/1.png`)"
                                        alt="Banner Images" />
                                    <div class="bottom-title">
                                        藤椒鲜麻味
                                    </div>
                                </div>
                                <div class="bottom-item" data-aos="fade-up" data-aos-delay="100">
                                    <img class="bottom-img" :src="require(`@/assets/images/index/shop/1.png`)"
                                        alt="Banner Images" />
                                    <div class="bottom-title">
                                        番茄鸳鸯锅酸甜味
                                    </div>
                                </div>
                                <div class="bottom-item" data-aos="fade-up" data-aos-delay="100">
                                    <img class="bottom-img" :src="require(`@/assets/images/index/shop/1.png`)"
                                        alt="Banner Images" />
                                    <div class="bottom-title">
                                        清汤滋补鱼鲜味
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div> 
          <div class="divider"></div>-->

            <div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">
                                    香辣红油锅
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">香辣红油味</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        香辣红油味锅底以其独特的麻辣和香料风味为人所熟知。浓郁的红油与香料完美融合，每一口都带有火辣的感觉，让人回味无穷。
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/1.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">
                                    酸菜鲜辣锅
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">酸菜鲜辣味</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        酸菜鲜辣味锅底结合了酸爽的酸菜和辣椒的鲜辣，独特的酸辣口感让你的味蕾在这种美食中得到极大的满足，不断地让人想要尝试下一口。
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-2">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/1.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">
                                    藤椒鲜麻锅
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">藤椒鲜麻味</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        藤椒鲜麻味锅底采用特有的藤椒，麻辣的同时又保持了食材的鲜嫩。
                                    </li>
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        这种口味既麻又鲜，让你的味蕾在其中得到双重享受。
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/1.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">
                                    番茄鸳鸯锅
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">酸甜味</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        番茄鸳鸯锅锅底以清新的番茄汤底为基础，酸甜的口感与其他食材完美结合。
                                    </li>
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        不仅清爽不腻，而且还有丰富的营养，是健康美食的代表
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-2">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/1.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">
                                    清汤滋补鱼鲜锅
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">清汤滋补鱼鲜味</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        清汤滋补鱼锅底采用清澈透明的清汤，配以鲜嫩的滋补鱼肉。这种口味既清新又鲜美，吃后不仅口感满足，还能享受到滋补的效果。
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/1.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-1 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">美蛙
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">只卖鲜活蛙</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        每日现宰现杀，确保食材的新鲜度和品质，提供最佳的味道体验。
                                    </li>
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        口感美味且肉质鲜嫩，彰显出其新鲜与高品质。
                                    </li>
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        蛙肥量足，肉质丰满，口感饱满。
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-2">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/1.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/2.png`)" alt="Banner Images" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40">
                            <div class="inner text-right">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">花鲢鱼
                                </h1>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        花鲢鱼肉质鲜嫩，味道鲜美。经过精心的烹饪处理，鱼肉鲜嫩多汁，吃后回味无穷，是鱼类美食的极品。
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-2">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/2.png`)" alt="Banner Images" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div class="inner text-right">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">乌鱼
                                </h1>
                                <!-- <h2 data-aos="fade-up" data-aos-delay="150">鲜美的乌鱼</h2> -->
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        乌鱼来源于深海，肉质鲜嫩，富含蛋白质和营养元素。
                                    </li>
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        其独特的口感和味道使其成为许多人的最爱，是健康食品的首选。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/2.png`)" alt="Banner Images" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40">
                            <div class="inner text-right">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">黄辣丁
                                </h1>
                                <!-- <h2 data-aos="fade-up" data-aos-delay="150">鲜美的黄辣丁</h2> -->
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        黄辣丁口感鲜美，香辣味浓郁。经过精心的烹饪后，每一颗都是香辣可口，是辣食爱好者们的最爱，不容错过。
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-2">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/2.png`)" alt="Banner Images" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div class="inner text-right">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">黔鱼
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">鲜美的黔鱼</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        黔鱼来自黔地，鱼肉鲜嫩，口感细腻，独特的地方风味使其成为许多人的最爱。
                                    </li>
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        鲜美的黔鱼肉质和浓郁的汤底
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/2.png`)" alt="Banner Images" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40">
                            <div class="inner text-right">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">
                                    麒麟大口茶
                                </h1>
                                <h2 data-aos="fade-up" data-aos-delay="150">火遍全网的麒麟大口茶</h2>
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        饮用后清新解渴，口感醇厚，深受广大消费者喜爱。
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent height-650">
                <div class="container">
                    <div class="row align-items-center">

                        <div class="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div class="inner text-left">
                                <h1 class="title theme-gradient display-one" data-aos="fade-up" data-aos-delay="100">冰豆花
                                </h1>
                                <!-- <h2 data-aos="fade-up" data-aos-delay="150">鲜美的冰豆花</h2> -->
                                <ul class="list-icon" data-aos="fade-up" data-aos-delay="200">
                                    <li>
                                        <Icon name="check" size="20"
                                            icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                        清凉解暑的冰豆花，甜而不腻，口感细腻，是夏日必备的美食
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xl-6 order-2 order-lg-2">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/2.png`)" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="divider"></div>
            <div class="slider-area slider-style-2 bg-transparent">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-xl-6 order-1 order-lg-1">
                            <div data-aos="fade-up" data-aos-delay="100">
                                <img :src="require(`@/assets/images/index/shop/food.png`)" alt="Banner Images" />
                            </div>
                        </div>
                        <div class="col-lg-8 col-xl-6 order-2 order-lg-2 mt_md--40 mt_sm--40 food-list">
                            <img v-for="(item, index) in foodList" :key="index" class="food-img" :src="item"
                                alt="Sun images" />
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- End 产品介绍 -->

        <!-- Start 合作流程 -->
        <Separator />
        <div class="rwt-timeline-area rn-section-gap bg-vice">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="合作流程" data-aos="fade-up"
                            data-aos-delay="60" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 joining-process">


                        <Timeline :timelineStyle="1" :timeline-data="timelineData" />
                    </div>
                </div>
            </div>
        </div>
        <Separator />
        <!-- Start 关于我们 -->
        <AboutOne :image="require(`@/assets/images/about/hg.png`)" />
        <!-- End 关于我们 -->
        <Separator />
    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import ServiceOne from '@/components/elements/service/ServiceOne'
import AboutOne from '@/components/elements/about/AboutOne'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPostMixin from '@/mixins/BlogPostMixin'
import BlogPost from '@/components/blog/BlogPost'
import Icon from '@/components/icon/Icon'
import Timeline from "@/components/elements/timeline/Timeline";
// import Gallery from '@/components/elements/gallery/Gallery'
import Testimonial from '@/components/elements/testimonial/Testimonial'
import Swiper from "swiper"
export default {
    name: "index",
    components: {
        Testimonial,
        Timeline,
        // Gallery, 
        BlogPost, AboutOne, ServiceOne, SectionTitle, Separator, Layout, VueSlickCarousel, Icon
    },
    mixins: [BlogPostMixin],
    data() {
        return {
            shopSliderSetting: {
                // multiple: true,
                accessibility: false,
                arrows: false,
                // infinite: true,
                dots: false,
                arrows: true,
                autoplay: true,
                speed: 5000,
                autoplaySpeed: 0,
                cssEase: 'linear',
                initialSlide: 0,
                // variableWidth: true,
                // slidesToShow: 4,
                slidesToScroll: 1,
                swipeToSlide: true

            },
            sliderSetting: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 5000,

            },
            excellentStoreList: [
                {
                    image: '1',
                    // title: '呈贡店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '2',
                    // title: '玉溪店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '3',
                    // title: 'xx店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '4',
                    // title: 'xx店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '5',
                    // title: 'xx店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '6',
                    // title: 'xx店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '7',
                    // title: 'xx店.',
                    // description: `地址：xxxxxxxxxxx.`
                },
                {
                    image: '8',
                    // title: 'xx店.',
                    // description: `地址：xxxxxxxxxxx.`
                },

            ],
            sliderData: [
                {
                    image: '2',
                    title: '',
                    description: ``
                }
            ],
            sceneList: [
                require(`@/assets/images/index/scene/1.png`),
                require(`@/assets/images/index/scene/2.png`),
                require(`@/assets/images/index/scene/3.png`),
                require(`@/assets/images/index/scene/4.png`),
                require(`@/assets/images/index/scene/5.png`),
                require(`@/assets/images/index/scene/6.png`),
                require(`@/assets/images/index/scene/7.png`),
                require(`@/assets/images/index/scene/10.png`),
            ],
            foodList: [
                require(`@/assets/images/index/shop/11.jpg`),
                require(`@/assets/images/index/shop/12.jpg`),
                require(`@/assets/images/index/shop/13.jpg`),
                require(`@/assets/images/index/shop/4.jpg`),
                require(`@/assets/images/index/shop/5.jpg`),
                require(`@/assets/images/index/shop/6.jpg`),
                require(`@/assets/images/index/shop/2.jpg`),
                require(`@/assets/images/index/shop/3.jpg`),
                require(`@/assets/images/index/shop/9.jpg`),
                require(`@/assets/images/index/shop/7.jpg`),
                require(`@/assets/images/index/shop/10.jpg`),
                require(`@/assets/images/index/shop/26.jpg`),

            ],
            // 合作流程
            timelineData: [
                {
                    id: '1',
                    title: '①:电话或官网咨询.',
                },
                {
                    id: '2',
                    title: '②：履历初步审核.',
                },
                {
                    id: '3',
                    title: '③：初次面谈.',
                },
                {
                    id: '4',
                    title: '④：股东架构审核及能力考察.',
                },
                {
                    id: '5',
                    title: '⑤：签订意向协议书.',
                },
                {
                    id: '6',
                    title: '⑥：实地选址考察.',
                },
                {
                    id: '7',
                    title: '⑦：正式签约.',
                }
            ],
        }
    },
    mounted() {
        //在mounted调用，因为这里dom已经初始化完成，js逻辑可以作用于dom了
        this.bannerPoint()
    },
    methods: {
        bannerPoint() {
            //这里return 我是设置了eslint规则，new字段不能单独写，要进行赋值
            return new Swiper('.swiper-container', {
                loop: true,
                speed: 8000,
                autoplay: {
                    delay: 0, // 1秒切换一次
                    disableOnInteraction: false,
                    stopOnLastSlide: false,
                    // waitForTransition: false,
                },
                freeMode: true,
                slidesPerView: 'auto',
                loopedSlides: 6,
                updateOnImagesReady: true,
                loopFillGroupWithBlank: true,
                spaceBetween: 0,
                // spaceBetween : '10%',按container的百分比

                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                // pagination: {
                // 	el: '.swiper-pagination',
                // 	clickable: false
                // }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.single-demo {
    background: #0c6b71;
    padding: 0;
}

.slider-style-1 .inner .list-icon li .icon {
    background-color: var(--color-vice) !important;
}

.slider-style-2 .inner .list-icon li .icon {
    background-color: var(--color-vice) !important;
}

.slider-style-2 {
    padding-top: 20px;
}

.bg-overlay {
    background-size: contain;

    &::before {
        background: #000000;
        content: '';
        opacity: 0.1 !important;
        background-image: none;
    }
}

.food-img {
    width: 30%;
    margin-right: 3%;
    margin-top: 20px;
}

::v-deep .swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
}

.divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgb(244, 207, 43) 0%, rgb(244, 202, 42) 6%, rgb(243, 193, 40) 12%, rgb(243, 186, 39) 20%, rgb(242, 174, 36) 41%, rgb(242, 169, 35) 53%, rgb(241, 154, 32) 64%, rgb(241, 146, 30) 77%, rgb(240, 137, 28) 100%) !important;
}

.bottom-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .bottom-item {
        margin-right: 20px;
        margin-bottom: 20px;

        .bottom-img {
            // width: 150px;
            height: 200px;
            display: block;
        }

        .bottom-title {
            font-weight: 600;
            font-size: 30px;
        }
    }

}
</style>