<template>
    <Layout>
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                        :style="{ 'background-image': `url(${require(`@/assets/images/cooperation/${slider.image}.png`)})` }">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title" />
                                        <p class="description" v-html="slider.description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <Separator /> -->
        <!-- Start 签约案例 -->
        <div class="rn-demo-area rn-section-gap" id="user-story">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="" title="签约案例"
                            description="下面是我们店的签约案例 <br /> 一起来感受专业态度带来的不同" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-3 col-md-6 col-12 mb--20" v-for="(item, index) in sceneList" :key="index">
                        <div class="single-demo">
                            <div class="thumbnail">
                                <el-image class="image-dark" :preview-src-list='sceneList' :src="item"
                                    style="width: 100%;background-size: 100% 100%;" alt="Testimonial image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 签约案例 -->

        <!-- Start 合作流程 -->
        <Separator />
        <div class="rwt-timeline-area rn-section-gap bg-vice">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="" title="合作流程" data-aos="fade-up"
                            data-aos-delay="60" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 joining-process">
                        <Timeline :timelineStyle="1" :timeline-data="timelineData" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End 签约案例 -->
        <!-- Start 合作要求 -->
        <!-- <Separator />
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="" title="合作要求" data-aos="fade-up"
                            data-aos-delay="60" />
                    </div>
                </div>

                <div class="row rwt-portfolio-details">
                    <div class="col-lg-6 joining-process content-left">
                        <div class="single-list-wrapper">
                            <h3 style="justify-content: flex-start;" v-for="(item, index) in cooperationData"
                                :key="index">
                                <label>{{ index + 1 }}:</label>
                                <span>{{ item.text }}</span>
                            </h3>
                            <div data-aos="fade-up" data-aos-delay="100">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 joining-process content-left">
                        <div data-aos="fade-up" data-aos-delay="100">
                            <img :src="require(`@/assets/images/cooperation/hezuo.png`)" alt="Banner Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End 合作要求 -->
        <Separator />

    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPostMixin from '@/mixins/BlogPostMixin'
import BlogPost from '@/components/blog/BlogPost'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Testimonial from '@/components/elements/testimonial/Testimonial'
import Separator from '@/components/elements/separator/Separator'
import Timeline from "@/components/elements/timeline/Timeline";
import Brand from '@/components/elements/brand/Brand'
export default {
    name: "index",
    components: {
        Testimonial, Separator,
        BlogPost, Layout, VueSlickCarousel, SectionTitle, Timeline, Brand
    },
    mixins: [BlogPostMixin],
    data() {
        return {
            sliderSetting: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                autoplay: true,

            },
            sliderData: [
                {
                    image: 'banner1',
                    title: '蛙多宝111.',
                    description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                },
                {
                    image: 'banner2',
                    title: '蛙多宝222.',
                    description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                },
                {
                    image: 'banner3',
                    title: '蛙多宝333.',
                    description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                },
            ],
            sceneList: [
                require(`@/assets/images/cooperation/signed/signed_1.png`),
                require(`@/assets/images/cooperation/signed/signed_1.png`),
                require(`@/assets/images/cooperation/signed/signed_1.png`),
                require(`@/assets/images/cooperation/signed/signed_1.png`),
            ],

            // 合作流程
            timelineData: [
                {
                    id: '1',
                    title: '①:电话或官网咨询.',
                },
                {
                    id: '2',
                    title: '②：履历初步审核.',
                },
                {
                    id: '3',
                    title: '③：初次面谈.',
                },
                {
                    id: '4',
                    title: '④：股东架构审核及能力考察.',
                },
                {
                    id: '5',
                    title: '⑤：签订意向协议书.',
                },
                {
                    id: '6',
                    title: '⑥：实地选址考察.',
                },
                {
                    id: '7',
                    title: '⑦：正式签约.',
                }
            ],
            cooperationData: [
                { text: '老板自行管理店铺' },
                { text: '女生负责现场管理（见面审核）' },
                { text: '装修风格相同' },
                { text: '按品牌要求经营' },
                { text: '一楼商铺或者一二楼' },
                { text: '门头不低于12米' },
            ]

        }
    },

}
</script>
<style lang="scss" scoped>
.single-demo {
    background: #0C6B71;
    padding: 0;
}

.icon-img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.el-popover-img {
    width: 400px;
    // height: 100px;
    display: block;
    margin: auto;
}

.brandList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
</style>