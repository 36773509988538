<template>
	<ul class="mainmenu">
		<li class="has-droupdown has-menu-child-item" :class="getclass('/') ? 'active' : ''">
			<router-link to="/">首页</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item" :class="getclass('/brand') ? 'active' : ''">
			<router-link to="/brand">公司品牌</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item" :class="getclass('/store') ? 'active' : ''">
			<router-link to="/store">门店介绍</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item" :class="getclass('/help') ? 'active' : ''">
			<router-link to="/help">总部扶持</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item" :class="getclass('/cooperation') ? 'active' : ''">
			<router-link to="/cooperation">合作方式</router-link>
		</li>
		<li :class="getclass('/contact') ? 'active' : ''">
			<router-link to="/contact">联系我们</router-link>
		</li>
	</ul>
</template>

<script>
import Icon from "../../icon/Icon";
export default {
	name: 'Nav',
	components: { Icon },
	data() {
		return {
			path: '',
			storeListData: [
				{
					title: '装修风格'
				},
				{
					title: '火爆现场'
				},
				{
					title: '产品介绍'
				},
			],
		}
	},
	created() {
		this.path = this.$route.path
	},
	mounted() {

	},
	methods: {
		getclass(e) {
			return e === this.path
		}
	}
}
</script>
