<template>
    <Layout>
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                        :style="{ 'background-image': `url(${require(`@/assets/images/cooperation/${slider.image}.png`)})` }">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title" />
                                        <p class="description" v-html="slider.description" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <Separator /> -->
        <!-- Start 地址扶持 -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle="" title="地址扶持" description="Address to support"
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            店面的选址是加盟商提交预选场地、市场简要报告、提出考察申请，总公司根据实际情况确定派出人员专项实地考察。选址标准按照使用面积300平米以内，临街商铺，成熟商业网点集中地，人口聚集处，交通便利等，能达到候选门店调研评估标准。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 地址扶持 -->
        <Separator />
        <!-- Start 培训支持 -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle="" title="培训支持" description="Training support"
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            总部给予严格的培训支持，培训需要在装修时间内进行，严格按照公司要求招聘店长及厨师长等相关主要店面人员，送至总公司进行培训，培训时间为30-60天，培训内容为掌握经营店铺所需的技术、操作流程和制作工艺，以及筹备店面与经营所必须的技能，学习的店铺的收银、管理、后厨管理等。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 培训支持 -->
        <Separator />
        <!-- Start 开业支持 -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle="" title="开业支持" description="Business support"
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            总部将给予加盟店开业筹备、开业培训、开业督导、营销等一条龙服务支持。协助制定相应的开业营销方案、活动执行情况跟进，接受加盟店的营销咨询，根据店铺经营情况，结合市场调研给予相关营销建议。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 开业支持 -->
        <Separator />
        <!-- Start 宣传支持 -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <SectionTitle text-align="center" subtitle="" title="后期扶持" description="Late support"
                                data-aos="fade-up" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            成立营销团队，根据门店实际情况协助，量身定制营销计划，进行推广。总部协助制定相应的营销方案、活动执行情况跟进，接受加盟店的营销咨询，根据店铺经营情况，结合市场调研给予相关营销建议。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 宣传支持 -->

        <Separator />

    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPostMixin from '@/mixins/BlogPostMixin'
import BlogPost from '@/components/blog/BlogPost'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Testimonial from '@/components/elements/testimonial/Testimonial'
import Separator from '@/components/elements/separator/Separator'
import YunNanMap from "@/components/map/YunNanMap.vue";
import Timeline from "@/components/elements/timeline/Timeline";
import Brand from '@/components/elements/brand/Brand'
export default {
    name: "index",
    components: {
        Testimonial, Separator,
        BlogPost, Layout, VueSlickCarousel, SectionTitle, YunNanMap, Timeline, Brand
    },
    mixins: [BlogPostMixin],
    data() {
        return {
            sliderSetting: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 993,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 481,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            },
            sliderData: [
                {
                    image: 'banner1',
                    title: '蛙多宝111.',
                    description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                },
                {
                    image: 'banner2',
                    title: '蛙多宝222.',
                    description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                },
                {
                    image: 'banner3',
                    title: '蛙多宝333.',
                    description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                },
            ],
            sceneList: [
                require(`@/assets/images/index/scene/1.png`),
                require(`@/assets/images/index/scene/2.png`),
                require(`@/assets/images/index/scene/3.png`),
                require(`@/assets/images/index/scene/4.png`),
                require(`@/assets/images/index/scene/5.png`),
                require(`@/assets/images/index/scene/6.png`),
                require(`@/assets/images/index/scene/7.png`),
                require(`@/assets/images/index/scene/10.png`),
            ],

            tableData: [],


            brandList: [
                {
                    href: "https://aliyun.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.nginx.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.189.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://aliyun.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.ynnu.edu.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "http://www.repairct.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.ynskw.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://colab.research.google.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.tapd.cn/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.toptal.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
                {
                    href: "https://www.thoughtworks.com/",
                    image: require(`@/assets/images/about/douyin.png`)
                },
            ],
        }
    },
    methods: {

    },
}
</script>
<style lang="scss" scoped>
.single-demo {
    background: #0C6B71;
    padding: 0;
}

.icon-img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.el-popover-img {
    width: 400px;
    // height: 100px;
    display: block;
    margin: auto;
}

.brandList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
</style>