<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
            <div class="footer-top">
                <div class="container">
                    <div class="row" style="justify-content: end;">
                        <div class="col-lg-8 col-md-6 col-sm-6 col-12 footer-mg-b-20">
                            <div class="rn-footer-widget">
                                <Logo />
                                <h3 class="title" style="margin-top: 20px;">蛙多宝美蛙鱼头火锅</h3>
                                <h6 class="subtext">联系电话：4006899001</h6>
                                <h6 class="subtext">地址：云南省昆明市呈贡区渔博路金盾俊园5幢8号商铺1−2层</h6>

                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <el-popover placement="top" trigger="hover">
                                    <img class="el-popover-img" :src="require('@/assets/images/about/headquarters.png')"
                                        alt />
                                    <li slot="reference">
                                        <a href="javascript:void(0);">
                                            <!-- 微信号-->
                                            <img class="icon-img light-icon"
                                                :src="require(`@/assets/images/about/headquarters.png`)"
                                                alt="Sun images" />
                                        </a>
                                    </li>
                                </el-popover>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <el-popover placement="top" trigger="hover">
                                    <img class="el-popover-img" :src="require('@/assets/images/about/douyin.png')"
                                        alt />
                                    <li slot="reference">
                                        <a href="javascript:void(0);">
                                            <!-- 抖音号-->
                                            <img class="icon-img light-icon"
                                                :src="require(`@/assets/images/about/douyin.png`)" alt="Sun images" />
                                        </a>
                                    </li>
                                </el-popover>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
							<img class="" :src="require(`@/assets/images/footer/tousu.png`)"
								alt="Sun images" />
						</div> -->
                    </div>
                </div>
            </div>
        </footer>
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright" />

        <ScrollTop />
    </div>
</template>

<script>
import Copyright from './Copyright'
import Button from '../../elements/button/Button'
import Icon from '../../icon/Icon'
import ScrollTop from './ScrollTop'
import Logo from '../../elements/logo/Logo'
import AppFunctions from '@/helpers/AppFunctions'
export default {
    name: 'Footer',
    components: { Logo, ScrollTop, Icon, Button, Copyright },
    props: {
        data: {
            default: null
        }
    },

    data() {
        return {
            AppFunctions,

        }
    },
    created() {


    },
}
</script>
<style lang="scss" scoped>
.icon-img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.el-popover-img {
    width: 400px;
    // height: 100px;
    display: block;
    margin: auto;
}

li {

    list-style: none;
}
</style>
