<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                        :style="{ 'background-image': `url(${require(`@/assets/images/store/banner/${slider.image}.png`)})` }">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title" />
                                        <p class="description" v-html="slider.description" />
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default" href="#">
                                                PURCHASE NOW
                                            </a>
										</div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <Separator />
        <!-- Start counter Area -->
        <div class="rwt-counterup-area pb--30">
            <div class="container">
                <Counter text-align="center" :counter-data="counterData" column="col-lg-6 col-md-6 col-sm-6 col-12"
                    class />
            </div>
        </div>
        <!-- End counter Area -->
        <!-- Start 装修风格 -->
        <div class="rn-demo-area rn-section-gap" id="user-story">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="装修风格" description="简约、大气、明亮、舒适"
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-3 col-md-6 col-12 mb--20" v-for="(item, index) in furnishingList" :key="index">
                        <div class="single-demo">
                            <div class="thumbnail">
                                <el-image class="image-dark banner_img" :preview-src-list="furnishingList" :src="item"
                                    style="width: 100%;height: 270px;background-size: 100% 100%;"
                                    alt="Testimonial image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 装修风格 -->
        <!-- Start 火爆现场 -->
        <div class="rn-demo-area rn-section-gap" id="user-story">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="火爆现场"
                            description="蛙多宝美蛙鱼头火锅，深受广大消费者喜爱。 <br /> 火爆用餐现场" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-3 col-md-6 col-12 mb--20" v-for="(item, index) in sceneList" :key="index">
                        <div class="single-demo">
                            <div class="thumbnail">
                                <el-image class="image-dark banner_img" :preview-src-list="sceneList" :src="item"
                                    style="width: 100%;height: 270px;background-size: 100% 100%;"
                                    alt="Testimonial image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 火爆现场 -->
        <!-- Start 产品介绍 -->
        <div class="rn-demo-area rn-section-gap" id="user-story">
            <div class="wrapper plr--30 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle title="菜品展示"
                            description="下面是我们店的菜品介绍 <br /> 一起来感受我们丰富多彩的菜品" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide width-390" v-for="(slider, index) in 20" :key="index">
                                <div class="bg_image mr--20 height-280">
                                    <img class="VueSlickCarouselimg banner_img"
                                        :src="require(`@/assets/images/index/shop/${slider}.jpg`)" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <VueSlickCarousel v-bind="shopSliderSetting" :slidesToShow="1" class="d-block d-lg-none">
						<div v-for="(slider, index) in 20" :key="index" class="single-slide">
							<div class="bg-overlay bg_image height-280 mr--20">
								<img class="VueSlickCarouselimg" :src="require(`@/assets/images/index/shop/${slider}.jpg`)" alt />
							</div>
						</div>
					</VueSlickCarousel>
					<VueSlickCarousel v-bind="shopSliderSetting" :slidesToShow="4" class="d-none d-lg-block">
						<div v-for="(slider, index) in 20" :key="index" class="single-slide">
							<div class="bg-overlay bg_image height-280 mr--20">
								<img class="VueSlickCarouselimg" :src="require(`@/assets/images/index/shop/${slider}.jpg`)" alt />
							</div>
						</div>
					</VueSlickCarousel>-->
                </div>
            </div>
        </div>
        <!-- End 产品介绍 -->
    </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPostMixin from '@/mixins/BlogPostMixin'
import BlogPost from '@/components/blog/BlogPost'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Testimonial from '@/components/elements/testimonial/Testimonial'
import Separator from '@/components/elements/separator/Separator'
import Counter from '@/components/elements/counterUp/Counter'
import Swiper from "swiper"
export default {
    name: "index",
    components: {
        Testimonial, BlogPost, Layout, VueSlickCarousel, SectionTitle, Separator, Counter
    },
    mixins: [BlogPostMixin],
    data() {
        return {
            shopSliderSetting: {
                // multiple: true,
                accessibility: false,
                arrows: false,
                // infinite: true,
                dots: false,
                arrows: true,
                autoplay: true,
                speed: 5000,
                autoplaySpeed: 0,
                cssEase: 'linear',
                initialSlide: 0,
                // variableWidth: true,
                // slidesToShow: 4,
                slidesToScroll: 1,
                swipeToSlide: true

            },
            counterData: [
                {
                    number: 28,
                    title: '全云南',
                },
                {
                    number: 14,
                    title: '昆明',
                }
            ],
            sliderSetting: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                autoplay: true,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 993,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 481,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            },
            sliderData: [
                {
                    image: 'banner-1',
                    title: '',
                    description: ``
                },
                // {
                // 	image: 'banner-1',
                // 	title: '蛙多宝222.',
                // 	description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                // },
                // {
                // 	image: 'banner-1',
                // 	title: '蛙多宝333.',
                // 	description: `蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝, <br /> 蛙多宝蛙多宝蛙多宝蛙多宝蛙多宝.`
                // },
            ],
            //装修风格
            furnishingList: [
                require(`@/assets/images/store/furnishing/1.png`),
                require(`@/assets/images/store/furnishing/2.png`),
                require(`@/assets/images/store/furnishing/3.png`),
                require(`@/assets/images/store/furnishing/4.png`),
                require(`@/assets/images/store/furnishing/5.png`),
                require(`@/assets/images/store/furnishing/6.png`),
                require(`@/assets/images/store/furnishing/7.png`),
                require(`@/assets/images/store/furnishing/8.png`),

            ],
            //火爆现场
            sceneList: [
                require(`@/assets/images/index/scene/1.png`),
                require(`@/assets/images/index/scene/2.png`),
                require(`@/assets/images/index/scene/3.png`),
                require(`@/assets/images/index/scene/4.png`),
                require(`@/assets/images/index/scene/5.png`),
                require(`@/assets/images/index/scene/6.png`),
                require(`@/assets/images/index/scene/7.png`),
                require(`@/assets/images/index/scene/10.png`),
            ],

        }
    },
    mounted() {
        //在mounted调用，因为这里dom已经初始化完成，js逻辑可以作用于dom了
        this.bannerPoint()
    },
    methods: {
        bannerPoint() {
            //这里return 我是设置了eslint规则，new字段不能单独写，要进行赋值
            return new Swiper('.swiper-container', {
                loop: true,
                speed: 8000,
                autoplay: {
                    delay: 0, // 1秒切换一次
                    disableOnInteraction: false,
                    stopOnLastSlide: false,
                },
                freeMode: true,
                slidesPerView: 'auto',
                loopedSlides: 6,
                updateOnImagesReady: true,
                loopFillGroupWithBlank: true,
                spaceBetween: 0,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.single-demo {
    background: #0c6b71;
    padding: 0;
}

.bg-overlay {
    background-size: contain;

    &::before {
        background: #000000;
        content: '';
        opacity: 0.1 !important;
        background-image: none;
    }
}

::v-deep .swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
}
</style>